import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Hero from "../../components/layouts/Hero";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../../components/Loading";
import db from "../../firebase";

const KidsWear = () => {
  const [loading, setLoading] = useState(false);
  const [kidsWear, setKidsWear] = useState();
  const kidsRef = doc(db, "Pages", "Kid");

  useEffect(() => {
    setLoading(true);
    getDoc(kidsRef).then((data) => {
      setKidsWear(data.data());
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <section className="md:h-screen">
            <Hero
              title={kidsWear?.H1}
              subTitle={kidsWear?.subTitle}
              content={kidsWear?.H2}
              buttonLink={"/contact"}
              buttonText={"Contact Us"}
            />
          </section>
          <section className="w-screen flex justify-center bg-white">
            <div className="flex w-4/5 gap-2 md:gap-5">
              <div className="w-1/3 flex flex-col gap-2 md:gap-5">
                <img
                  src={kidsWear?.Col1Img1}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col1Img2}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col1Img3}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col1Img4}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
              </div>
              <div className="w-1/3 flex flex-col gap-2 md:gap-5">
                <img
                  src={kidsWear?.Col2Img1}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col2Img2}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col2Img3}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col2Img4}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
              </div>
              <div className="w-1/3 flex flex-col gap-2 md:gap-5">
                <img
                  src={kidsWear?.Col3Img1}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col3Img2}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col3Img3}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
                <img
                  src={kidsWear?.Col3Img4}
                  className="hover:scale-105 duration-300"
                  alt="Madhur Exports Kidswear Images"
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default KidsWear;
