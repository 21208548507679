import React from "react";
import { SocialIcon } from "react-social-icons";

const Team = () => {
  const Contents = [
    {
      id: 1,
      href: "#",
      imageSrc:
        "https://res.cloudinary.com/dqj5beowr/image/upload/v1683487155/Team/IMG_8468_2_niordl.jpg",
      imageAlt: "Mohit Kuhad's Profile Picture",
      name: "Mohit Kuhad",
      title: "React Js Developer",
      paragraph: `The React Js Developer internship provided hands-on experience in developing responsive and dynamic user interfaces. Challenging projects improved my problem-solving skills. Overall, it was a fantastic learning experience.`,
      URLs: [
        { url: "https://www.linkedin.com/in/mohit-kuhad/" },
        { url: "https://github.com/Mohitkuhad" },
      ],
    },{
      id: 1,
      href: "#",
      imageSrc:
        "https://res.cloudinary.com/dqj5beowr/image/upload/v1681587507/Team/Addisu_ffpcre.png",
      imageAlt: "Addisu Haile's Profile Picture",
      name: "Addisu Haile",
      title: "Full Stack Developer",
      paragraph: `As a Full Stack Developer intern, I gained valuable experience in front-end development while working with a supportive team on real-world projects.`,
      URLs: [
        { url: "https://www.linkedin.com/in/addisu-tedla/" },
        { url: "https://github.com/addisu87" },
        { url: "https://angel.co/u/addisu87" },
      ],
    },
    {
      id: 2,
      href: "#",
      imageSrc:
        "https://res.cloudinary.com/dqj5beowr/image/upload/v1682677407/Team/PHOTO-2023-04-24-16-58-46_2_xa4ekl.jpg",
      imageAlt: "Christian Siku's Profile Picture",
      name: "Christian Siku",
      title: "Full Stack Developer",
      paragraph: `As an intern Full Stack Developer, I gained valuable experience in front-end development. The team was supportive, and the projects challenged me to improve my skills.`,
      URLs: [
        { url: "https://www.linkedin.com/in/christian-siku/" },
        { url: "https://github.com/Chrissiku" },
        { url: "https://twitter.com/christian_siku" },
      ],
    },
    {
      id: 3,
      href: "#",
      imageSrc:
        "https://res.cloudinary.com/dqj5beowr/image/upload/v1681587145/Team/FIdele_bspxgq.png",
      imageAlt: "Fidele Rurangwa's Profile Picture",
      name: "Fidele Rurangwa",
      title: "UI/UX Designer",
      paragraph: `I had a great experience as a UI/UX Designer intern. The team was supportive, and I learned how to create user-centered designs using tools such as Figma.`,
      URLs: [
        { url: "https://www.linkedin.com/in/addisu-tedla/" },
        { url: "https://github.com/addisu87" },
        { url: "https://angel.co/u/addisu87" },
      ],
    },
    {
      id: 3,
      href: "#",
      imageSrc:
        "https://res.cloudinary.com/dqj5beowr/image/upload/v1681588153/Team/rachel_epgu3f.jpg",
      imageAlt: "Rachel Gordon-Acavia's Profile Picture",
      name: "Rachel Gordon-Acavia",
      title: "Content writer",
      paragraph: `Interned as a Content Writer - gained experience in SEO, copywriting, and editing. Learned to write engaging and informative content for diverse audiences. Positive and valuable learning experience.`,
      URLs: [
        { url: "https://www.linkedin.com/in/rachel-gordon-acavia-1104aa125" },
      ],
    },
  ];
  return (
    <div className="mx-auto lg:px-10 px-2 py-10 overflow-hidden">
      <div className="mx-auto text-center ">
        <div className="pt-20 pb-15 space-y-10">
          <h1 className="md:text-4xl text-xl font-bold text-black">Our team</h1>
          {Contents.map((item, i) => (
            <div
              key={i}
              className="group bg-white max-w-md mx-auto rounded-md drop-shadow-lg overflow-hidden md:max-w-3xl"
            >
              <div className="md:flex md:h-60">
                <div className="md:shrink-0 md:w-60 h-[250px] md:h-full">
                  <img
                    src={item.imageSrc}
                    alt=""
                    layout="fill"
                    className="w-full h-full object-cover"
                  />
                </div>

                <div className="p-6">
                  <h2 className="mb-1 capitalize tracking-wide font-semibold text-xl leading-tight hover:underline">
                    {item.name}
                  </h2>
                  <h4 className="text-[16px] capitalize text-lg font-semibold text-sky-800">
                    {item.title}
                  </h4>
                  <p className="text-[14px] first-letter:uppercase mt-2 text-slate-500 text-justify">
                    {item.paragraph}
                  </p>
                  <div className="flex flex-row items-center justify-center cursor-pointer gap-4 p-4">
                    {item.URLs.map((social, id) => (
                      <SocialIcon
                        key={social._id}
                        url={social.url}
                        fgColor="white"
                        bgColor="black"
                        style={{ height: 25, width: 25 }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;