import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Hero from "../../components/layouts/Hero";
import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase";
import Loading from "../../components/Loading";

const WomanWear = () => {
  const [loading, setLoadin] = useState(false);
  const [womenWear, setWomenWear] = useState("");
  const womenRef = doc(db, "Pages", "Women");

  useEffect(() => {
    setLoadin(true);
    getDoc(womenRef).then((doc) => {
      setWomenWear(doc.data());
      setLoadin(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <Loading/>
      ) : (
      <div>
      <Header />
      <section className="md:h-screen">
        <Hero
          title={womenWear?.H1}
          subTitle={womenWear?.subTitle}
          content={womenWear?.H2}
          buttonLink={"/contact"}
          buttonText={"Contact Us"}
        />
      </section>
      <section className="w-screen flex justify-center bg-white">
        <div className="flex w-4/5 gap-2 md:gap-5">
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img
              src={womenWear?.Col1Img1}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col1Img2}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col1Img3}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col1Img4}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
          </div>
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img
              src={womenWear?.Col2Img1}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col2Img2}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col2Img3}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col2Img4}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
          </div>
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img
              src={womenWear?.Col3Img1}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col3Img2}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col3Img3}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
            <img
              src={womenWear?.Col3Img4}
              className="hover:scale-105 duration-300"
              alt="Madhur Exports Womenwear Images"
            />
          </div>
        </div>
      </section>
      </div>
      )}
    </div>
  );
};

export default WomanWear;
