import React, { useState, useEffect} from "react";
import Header from "../../components/Header";
import Hero from "../../components/layouts/Hero";
import { doc, getDoc } from "firebase/firestore"
import db from "../../firebase";
import Loading from "../../components/Loading";

const KnitsWear = () => {
  const [loading, setLoading] = useState(false);
  const [knitsWear, setKnitsWear] = useState();
  const knitsRef = doc(db, "Pages", "Knit");

  useEffect(() => {
    setLoading(true);
    getDoc(knitsRef).then((data) => {
      setKnitsWear(data.data());
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
      <div>
      <Header />
      <section className="md:h-screen">
          <Hero title={knitsWear?.H1} subTitle={knitsWear?.subTitle} content={knitsWear?.H2} buttonLink={"/contact"} buttonText={"Contact Us"}/>
          </section>
      <section className="w-screen flex justify-center bg-white">
        <div className="flex w-4/5 gap-2 md:gap-5">
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img src={knitsWear?.Col1Img1} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col1Img2} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col1Img3} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col1Img4} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
          </div>
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img src={knitsWear?.Col2Img1} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col2Img2} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col2Img3} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col2Img4} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
          </div>
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img src={knitsWear?.Col3Img1} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col3Img2} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col3Img3} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
            <img src={knitsWear?.Col3Img4} className="hover:scale-105 duration-300" alt="Madhur Exports Knitswear Images"/>
          </div>
        </div>
        </section>
        </div>
      )}
    </div>
  );
};

export default KnitsWear;
