import React, { useState, useEffect } from "react";
import { ProductsList } from "../components";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

const Products = () => {
  const[loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timeout);
  }, []);


  const title = `Experience Quality with Our Products`;
  const text = `Madhur Exports is your one-stop shop for all your garment needs. 
    We specialize in custom-made Kids Wear, Men's Wear, Women Wear, Knitwears, and 
    Accessories. Our products are made with the highest quality materials, ensuring 
    comfort and durability. Our experienced team of professionals is dedicated to 
    providing the best service and quality possible. We can also customize your orders 
    to fit your exact specifications. Shop with us today for excellent quality and unbeatable 
    prices.`;

  return (
    <div>
      {loading ? (
        <Loading />
      ): (
      <>
      <Header />
      <div className="hero flex flex-col w-full justify-center items-center pt-32 md:h-screen pb-10">
        <img
          src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681028505/HomePage/world-map_xtenqn.svg"
          alt="home page background image"
          className=" absolute w-3/4 -z-10"
        />
        <h1 className="font-bold text-lg md:text-3xl text-logo-blue sm:w-[90vw] md:w-2/4 text-center pb-5 md:pb-10 md:tracking-widest ">
          {title}
        </h1>
        <p className="text-sm w-5/6 md:w-fit md:text-xl pb-10 text-center">
          <Link to="men_wear" className="cursor-pointer">MENWEAR | </Link>
          <Link to="women_wear" className="cursor-pointer">WOMENWEAR | </Link>
          <Link to="knits_wear" className="cursor-pointer">KNITSWEAR | </Link>
          <Link to="kids_wear" className="cursor-pointer">KIDSWEAR | </Link>
          <Link to="uniform" className="cursor-pointer">CORPORATE UNIFORM | </Link>
          <Link to="accessories" className="cursor-pointer">ACCESSORIES</Link>
        </p>
        <div className="w-full flex justify-center">
        <p className="text-center w-5/6 text-xs md:text-sm">{text}</p>
        </div>
      </div>
      <ProductsList />
      </>
      )}
    </div>
  );
};

export default Products;