import React, { useState, useEffect } from "react";
import ProductCard from "./layouts/ProductCard";
import { doc, getDoc } from "firebase/firestore"
import db from "../firebase"


const ProductsList = () => {
  const [product, setProducts] = useState([]);
  const productsRef = doc(db, "Pages", "Products")


  useEffect(() => {
    getDoc(productsRef).then((doc) => {
      setProducts(doc.data())
    })
  }, []);

  return (
    <div>
      <div className="grid lg:grid-cols-1 md:grid-cols-3 grid-cols-2 lg:gap-0  gap-4 px-3 mx-auto">
          <ProductCard
            title={product.MenH1}
            image={product.MenImg}
            link={`products/${product.MenLink}`}
          />
          <ProductCard
            title={product.WomenH1}
            image={product.WomenImg}
            link={`products/${product.WomenLink}`}
          />
          <ProductCard
            title={product.KnitH1}
            image={product.KnitImg}
            link={`products/${product.KnitLink}`}
          />
          <ProductCard
            title={product.KidH1}
            image={product.KidImg}
            link={`products/${product.KidLink}`}
          />
          <ProductCard
            title={product.UniH1}
            image={product.UniImg}
            link={`products/${product.UniLink}`}
          />
          <ProductCard
            title={product.AccH1}
            image={product.AccImg}
            link={`products/${product.AccLink}`}
          />

      </div>
      <div className=" bg-[#e5e5e5] overflow-hidden mt-5">
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 text-light max-h-94 max-w-24 pr-5 overflow-y-scroll scrollbar-thin scrollbar-track-[#928c8cd5] scrollbar-thumb-[#e5e6e7f6]">
          <p className="text-justify text-xs md:text-sm">
            {product?.EndText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
