import React, { useEffect, useState } from "react";
import { Hero, ProductCarousel } from "../components";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";
import Header from "../components/Header";
import Loading from "../components/Loading";

const aboutRef = doc(db, "Pages", "About");

function About() {
  const [aboutContent, setAboutContent] = useState("")
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDoc(aboutRef).then((doc) => {
      setAboutContent(doc.data())
      setLoading(false);
    });
  }, []);

  return (
    <div className="h-auto">
      {loading ? (
        <Loading/>
      ) : (
        <div>
          <section className="md:h-screen w-screen border">
            <Header />
            <Hero 
            title={aboutContent?.H1}
            content={aboutContent?.H2}
            buttonLink="/products"
            buttonText="Products"
            />
          </section>
          <section className="md:h-screen md:w-screen flex items-center justify-center bg-white">
            <div className="flex flex-col md:flex-row w-full h-full justify-center items-center md:gap-32">
              <div className="w-5/6 md:w-[40vw] h-full md:flex md:flex-col justify-center py-5 md:py-20">
                <h1 className="font-bold  text-lg md:text-2xl pb-5 md:my-10 md:text-logo-red">
                  {aboutContent?.ContentH1}
                </h1>
                <p className="text-sm text-justify md:text-base">
                  {aboutContent?.ContentH2}
                </p>
              </div>
              <img
                src= {aboutContent?.ContentImg}
                alt="Madhur Exports Style Image for about page"
                className="md:h-4/5 rounded-3xl w-3/4 md:w-auto shadow-2xl object-cover"
              />
            </div>
          </section>
          <ProductCarousel />
        </div>
      )}
    </div>
  );
}

export default About;
