import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Hero } from "../components";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";
import Loading from "../components/Loading";

const Customization = () => {
  const [loading, setLoading] = useState(false);
  const [oemContent, setOemContent] = useState("");

  const oemRef = doc(db, "Pages", "OEM-ODM");

  useEffect(() => {
    setLoading(true);
    getDoc(oemRef).then((doc) => {
      setOemContent(doc.data());
      setLoading(false);
    });
  }, []);

  return (
    <div className="">
      {loading ? (
        <Loading />
      ) : (
        <div className="h-auto">
          <section className="h-screen w-screen">
            <Header />
            <Hero
              subTitle={oemContent?.SubTitle}
              title={oemContent?.H1}
              content={oemContent?.H2}
              buttonLink="/contact"
              buttonText="Contact Us"
            />
          </section>
          <section className="w-screen bg-white">
            <div className="flex flex-col md:flex-row w-full h-fit md:h-full justify-center items-center md:gap-32 py-10 md:py-0">
              <img
                src={oemContent?.Clothing1Img}
                alt="Madhur Exports OEM Service Decorative Image"
                className="md:h-[80vh] rounded-3xl w-3/4 md:w-2/6 object-cover shadow-2xl"
              />
              <div className="w-5/6 md:w-[40vw] h-fit md:h-full md:flex md:flex-col justify-center py-5 md:py-20">
                <h1 className="font-bold text-lg md:text-2xl pb-5 md:my-5 md:text-logo-red">
                  {oemContent?.Clothing1H1}
                </h1>
                <p className="text-justify text-sm md:text-base ">
                  {oemContent?.Clothing1H2}
                </p>
              </div>
            </div>
          </section>

          <section className="w-screen pt-10 bg-white">
            <div className="h-screen w-full flex flex-col items-center justify-center">
              <div className="w-3/4 h-44 md:h-56">
                <img
                  src={oemContent?.Clothing2Img}
                  alt="Madhur Exports OEM Service Decorative Image"
                  className="w-full h-full rounded-3xl shadow-xl object-cover"
                />
              </div>
              <div className="w-3/4 mt-10">
                <h1 className="text-base md:text-2xl font-bold pb-5 text-logo-red">
                  {oemContent?.Clothing2H1}
                </h1>
                <p className="text-justify text-sm md:text-lg">
                  {oemContent?.Clothing2H2}
                </p>
              </div>
            </div>
          </section>
          <section className="w-screen bg-white">
            <div className="flex flex-col md:flex-row w-full h-fit md:h-full justify-center items-center md:gap-32 py-10 md:py-0">
              <div className="w-5/6 md:w-[40vw] h-fit md:h-full md:flex md:flex-col justify-center py-5 md:py-20">
                <h1 className="font-bold text-lg md:text-2xl pb-5 md:my-5 md:text-logo-red">
                  {oemContent?.Clothing3H1}
                </h1>
                <p className="text-justify text-sm md:text-base ">
                  {oemContent?.Clothing3H2}
                </p>
              </div>
              <img
                src={oemContent?.Clothing3Img}
                alt="Madhur Exports OEM Service Decorative Image"
                className="md:h-[80vh] rounded-3xl w-3/4 md:w-2/6 object-cover shadow-2xl"
              />
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Customization;
