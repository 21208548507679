import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import {
  Home,
  Contact,
  Customization,
  More,
  Products,
  MenWear,
  WomanWear,
  Accessories,
  KnitsWear,
  KidsWear,
  OurBlogs,
  Uniform,
  Organic,
  Faq,
} from "./pages";
import About from "./pages/About";
import BlogPage from "./pages/BlogPage.js";
import Process from "./pages/Process";
import BottomMob from "./components/layouts/BottomMob";
import Certificate from "./pages/Certificate.js";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <div>
      <main>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/customization" exact element={<Customization />} />
          <Route path="/products" exact element={<Products />} />
          <Route path="/products/men_wear" exact element={<MenWear />} />
          <Route path="/products/women_wear" exact element={<WomanWear />} />
          <Route path="/products/accessories" exact element={<Accessories />} />
          <Route path="/products/knits_wear" exact element={<KnitsWear />} />
          <Route path="/products/kids_wear" exact element={<KidsWear />} />
          <Route path="/products/uniform" exact element={<Uniform />} />
          <Route path="/blogs" exact element={<OurBlogs />} />
          <Route path="/blogs/:id" element={<BlogPage />} />
          <Route path="/more" exact element={<More />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/organic" exact element={<Organic />} />
          <Route path="/faq" exact element={<Faq />} />
          <Route path="/process" exact element={<Process />} />
          <Route path="/certificate" exact element={<Certificate />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <BottomMob />
        <Footer />
      </main>
    </div>
  );
};

export default App;
