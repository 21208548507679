import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import db from "../../firebase";

const whatWeOffer1 =
  "https://res.cloudinary.com/dqj5beowr/image/upload/v1680957942/AboutPage/nimble-made-hMMXhKSZk7k-unsplash_1_hcuiiv.png";
const whatWeOffer2 =
  "https://res.cloudinary.com/dqj5beowr/image/upload/v1680957942/AboutPage/Rectangle_17_tmfsxl.png";
const whatWeOffer3 =
  "https://res.cloudinary.com/dqj5beowr/image/upload/v1680957942/AboutPage/Rectangle_13_qvevdf.png";
const whatWeOffer4 =
  "https://res.cloudinary.com/dqj5beowr/image/upload/v1680957942/AboutPage/Rectangle_14_eegl9i.png";
const whatWeOffer5 =
  "https://res.cloudinary.com/dqj5beowr/image/upload/v1680957943/AboutPage/unsplash_ZB4eQcNqVUs_utxytr.png";
const whatWeOffer6 =
  "https://res.cloudinary.com/dqj5beowr/image/upload/v1680957942/AboutPage/Rectangle_18_x8zhnw.png";

const ProductCarousel = () => {
  const productsRef = collection(db, "WhatWeOffer")
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      const data = await getDocs(productsRef);
      setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    getProducts();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div className="py-[40px] px-[10px] overflow-hidden products bg-white">
      <div className="container">
        <div className="h-auto space-y-1.5 md:space-y-2 text-center">
          <h2 className="mb-4 text-xl md:text-3xl text-blue font-bold py-5">
            What We Offer
          </h2>
          <div className="group relative mx-auto">
            <Slider {...settings}>
              {products.map((item, index) => (
                <div
                  key={index}
                  className="flex pb-2 flex-col min-w-[190px] max-w-[250px] space-y-2 items-center 
                  justify-center rounded-t-[30px] text-center hover-scale-105 cursor-pointer 
                  transition duration-500 easy-in-out drop-shadow-md"
                >
                  <div className="relative h-[250px] w-full">
                    <img
                      src={item.Img}
                      alt="product"
                      layout="fill"
                      className="object-cover h-full w-full rounded-[25px]"
                    />
                  </div>
                  <div className="w-full md:font-semibold text-lg md:text-xl">
                    {item.Name}
                  </div>
                  <Link to={item.Link}>
                    <button className="bg-white text-logo-red px-5 py-2 hover:bg-logo-red hover:text-white duration-300 mt-5 rounded-md">
                      View More
                    </button>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
