import React, { useState, useEffect} from "react";
import Header from "../../components/Header";
import Hero from "../../components/layouts/Hero";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../../components/Loading";
import db from "../../firebase";

const Accessories = () => {
  const[loading, setLoading] = useState(false);
  const[accessories, setAccessories] = useState();
  const accessoriesRef = doc(db, "Pages", "Accessories");

  useEffect(() => {
    setLoading(true);
    getDoc(accessoriesRef).then((data) => {
      setAccessories(data.data());
      setLoading(false);
    });
  }, []);

    return (
    <div>
      {loading ? (<Loading /> ):(
      <div>
      <Header />
      <section className="md:h-screen">
          <Hero title={accessories?.H1} subTitle={accessories?.subTitle} content={accessories?.H2} buttonLink={"/contact"} buttonText={"Contact Us"}/>
          </section>
      <section className="w-screen flex justify-center bg-white">
        <div className="flex w-4/5 gap-2 md:gap-5">
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img src={accessories?.Col1Img1} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col1Img2} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col1Img3} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col1Img4} className="hover:scale-105 duration-300"/>
          </div>
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img src={accessories?.Col2Img1} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col2Img2} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col2Img3} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col2Img4} className="hover:scale-105 duration-300"/>
          </div>
          <div className="w-1/3 flex flex-col gap-2 md:gap-5">
            <img src={accessories?.Col3Img1} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col3Img2} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col3Img3} className="hover:scale-105 duration-300"/>
            <img src={accessories?.Col3Img4} className="hover:scale-105 duration-300"/>
          </div>
        </div>
        </section>
        </div>
        )}
    </div>
  );
};

export default Accessories;
