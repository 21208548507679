import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase";

const Hero = ({ subTitle, title, content, buttonLink, buttonText }) => {
  const [bgImage, setBgImage] = useState("");
  const bgImageRef = doc(db, "Images", "HeroBg")

  useEffect(() => {
    getDoc(bgImageRef).then((doc) => {
      setBgImage(doc.data().url);
    })
  }, []);

  return (
    <div className="hero flex flex-col w-full h-full justify-center items-center pt-32">
      <img
        src={bgImage}
        className="fixed w-screen md:w-3/4 -z-10"
      />
      <p className="font-bold text-2xl mb-10 ">{subTitle}</p>
      <h1 className="font-bold text-base md:text-3xl text-logo-red w-[80vw] md:w-2/4 text-center pb-5 md:pb-10 md:tracking-widest ">
        {title}
      </h1>
      <p className="text-xs md:text-sm w-5/6 md:w-3/4 md:text-center text-justify">{content}</p>
      <Link to={buttonLink}>
        <button className="bg-white text-logo-red px-5 py-2 mb-10 rounded shadow-lg mt-5 md:mt-10 hover:bg-logo-red hover:text-white z-10 duration-300">
          {buttonText}
        </button>
      </Link>
    </div>
  );
};

export default Hero;