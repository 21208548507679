import React, { useState, useEffect} from "react";
import Header from "../components/Header";
import Hero from "../components/layouts/Hero";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";
import Loading from "../components/Loading";

const Organic = () => {
  const [loading, setLoading] = useState(false);
  const [organicContent, setOrganicContent] = useState("");

  const organicRef = doc(db, "Pages", "Organic");

  useEffect(() => {
    setLoading(true);
    getDoc(organicRef).then((doc) => {
      setOrganicContent(doc.data());
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
    <div className="h-auto">
      <Header />
      <section className="md:h-screen w-screen">
        <Header />
        <Hero subTitle={organicContent?.subTitle} title={organicContent?.H1} content={organicContent?.H2} buttonLink="/contact" buttonText="Contact Us"/>
      </section>
      <section className="w-screen bg-white">
        <div className="flex flex-col md:flex-row w-full h-fit md:h-full justify-center items-center md:gap-32 py-10 md:py-0">
          <img
            src={organicContent?.Clothing1Img}
            alt="clothing 1"
            className="md:h-[80vh] rounded-3xl w-3/4 md:w-auto shadow-2xl"
          />
          <div className="w-5/6 md:w-[40vw] h-fit md:h-full md:flex md:flex-col justify-center py-5 md:py-20">
            <h1 className="font-bold text-lg md:text-2xl pb-5 md:my-5 md:text-logo-red">
              {organicContent?.Clothing1H1}
            </h1>
            <p className="text-justify text-sm md:text-base ">{organicContent?.Clothing1H2}</p>
          </div>
        </div>
      </section>

      <section className="w-screen pt-5 bg-white">
        <div className="md:h-screen w-full flex flex-col items-center justify-center">
          <div className="w-3/4 h-32 md:h-56">
            <img src={organicContent?.Clothing2Img} className="h-full w-full rounded-3xl shadow-2xl"/>
          </div>
          <div className="w-3/4 mt-10">
            <h1 className="text-lg md:text-2xl font-bold md:pb-10 md:tracking-widest text-logo-red">
              {organicContent?.Clothing2H1}
            </h1>
            <p className="text-justify">{organicContent?.Clothing2H2}</p>
          </div>
        </div>
      </section>

      <section className="w-screen bg-white">
        <div className="flex flex-col md:flex-row w-full h-fit md:h-full justify-center items-center md:gap-32 py-10 md:py-0">
          <div className="w-5/6 md:w-[40vw] h-fit md:h-full md:flex md:flex-col justify-center py-5 md:py-20">
            <h1 className="font-bold text-lg md:text-2xl pb-5 md:my-5 md:text-logo-red">
              {organicContent?.Clothing3H1}
            </h1>
            <p className="text-justify text-sm md:text-base ">{organicContent?.Clothing3H2}</p>
          </div>
          <img
            src={organicContent?.Clothing3Img}
            alt="clothing 1"
            className="md:h-[80vh] rounded-3xl w-3/4 md:w-auto shadow-2xl"
          />
        </div>
      </section>
    </div>
    )}
    </div>
  );
};

export default Organic;