import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";
import Loading from "../components/Loading";

function Certificate() {
  const [search, setSearch] = useState("000");
  const [searched, setSearched] = useState(false);
  const [certificate, setCertificate] = useState({});
  const [verified, setVerified] = useState(null);
  const certificateRef = doc(db, "Certificates", search);
  const [loading, setLoading] = useState(true);

  const searchCertificate = () => {
    setSearched(true);
    getDoc(certificateRef).then((doc) => {
      setCertificate(doc.data());
      if (certificate == undefined) {
        setVerified(false);
      } else {
        setVerified(true);
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-screen flex flex-col items-center">
          <Header />
          <div className="py-12 mt-36 flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold">Certificate Verification</h1>
            <div className="border flex items-center mt-10">
              <input
                type="text"
                placeholder="Certificate Number"
                className="w-48 md:w-72 h-14 border-none"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              <button
                className="h-14 px-5 bg-green-500 text-white"
                onClick={() => {
                  searchCertificate(search);
                }}
              >
                Search
              </button>
            </div>
          </div>
          <div
            className={`w-screen flex-col items-center mt-40 ${
              searched ? "flex" : "hidden"
            }`}
          >
            <div
              className={`w-3/4 border border-gray-300 rounded-xl p-10 ${
                verified ? "flex" : "hidden"
              } flex-col gap-10`}
            >
              <div className="w-11/12 flex justify-between items-center mb-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
                  alt="Madhur Exports logo"
                  width="120px"
                />
                <div>
                  <p className="text-gray-400">
                    Certificate Number: <br />
                    {search}
                  </p>
                </div>
              </div>
              <div className="">
                <p>Certificate of Internship</p>
                <h1 className="text-3xl font-bold mt-5">
                  {certificate?.Field}
                </h1>
                <p className="text-3xl underline underline-offset-4">
                  {certificate?.Name}
                </p>
                <p className="mt-20">
                  Date of Completion: {certificate?.Date}
                  <br />
                  Duration: {certificate?.Duration}
                </p>
              </div>
            </div>
            <div
              className={`w-3/4 border border-gray-300 rounded-xl p-10 ${
                verified ? "hidden" : "flex"
              } flex-col gap-10 justify-center items-center`}
            >
              <h1 className="text-3xl">Unable to verify.</h1>
            </div>
            <div className={`${verified ? "flex" : "hidden"} w-3/4 mt-2`}>
              <p>
                This certificate above verifies that{" "}
                <span className="font-bold underline">
                  {certificate?.Name}{" "}
                </span>
                successfully his/her internship as{" "}
                <span className="font-bold underline">
                  {certificate?.Field}{" "}
                </span>{" "}
                in Madhur Exports on{" "}
                <span className="font-bold underline">
                  {certificate?.Date}{" "}
                </span>
                . The certificate indicates the internship was completed. The
                course duration represents the total duration{" "}
                {certificate?.Name} spent on the internship.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Certificate;
