import React from "react";

const ProductCard = ({ image, title, link }) => {
  return (
    <div className="lg:mb-0 lg:gap-0 gap-3 mx-auto lg:w-[90vw] lg:even:flex-row-reverse 
    flex lg:flex-nowrap flex-wrap items-center justify-center text-black">
      <div className="w-4/5 md:w-1/2 md:h-[400px] h-[130px]">
        <img
          src={image}
          alt={title}
          className="h-full w-full object-cover object-center lg:rounded-none rounded-xl shadow-md"
        />
      </div>
      <div className="flex flex-col lg:gap-5 items-center justify-center lg:w-1/2 w-full">
        <h3 className="md:font-semibold text-sm md:text-2xl">{title}</h3>
        <a className="text-logo-red" href={link}>
          <button className="shadow-md px-2 py-1 text-sm md:text-base md:px-5 md:py-2 rounded hover:bg-logo-red hover:text-white duration-300">
          View More
          </button>
        </a>
      </div>
    </div>
  );
};

export default ProductCard;
