import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import unsplash from "../images/unsplash.png";
import { getDocs, collection } from "firebase/firestore";
import db from "../firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function TestimonialCarousel() {

  const [posts, setPosts] = useState([]);
  const testimonialCollectionRef = collection(db, "Testimonials")

  useEffect(() => {
    const getPosts = async () => {
      const data = await getDocs(testimonialCollectionRef);
      setPosts(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }
    getPosts();
  },[])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: "linear",
    centerMode: true,
    // centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 320,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <div className="mx-auto lg:px-10 px-2 py-12 bg-white flex flex-col gap-4 overflow-hidden testimonials">
      <div className="mx-auto text-center">
        <h2 className="text-center text-2xl font-bold tracking-tight sm:text-4xl text-blue">
          Testimonials
        </h2>
      </div>

      <div className="bg-white">
        <Slider {...settings} className="lg:py-6 shadow-md lg:bg-[#0e47550c]">
          {posts.map((post, i) => (
            <div
            key={i}
              className="py-8 max-w-[350px] rounded-3xl bg-[#f5f5f5] shadow-md hover:bg-[#e5e5e5] hover:scale-105 transition-all duration-300 ease-in-out"
              >
              <img
                alt="profile-pic"
                src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681583211/Components/output-onlinepngtools_3_crmk7x.png"
                className="mx-auto h-[100px] w-[100px] rounded-full object-cover shadow-md shadow-blue"
                />
              <blockquote className="mt-3 flex flex-col justify-between rounded-lg px-5 text-center">
                <div className="ml-4 text-sm">
                  <p className="text-lg font-bold text-gray-700">{post.Name}</p>
                  <p className="mt-1 text-xs font-medium text-gray-500">
                    {post.Title}
                  </p>
                </div>
                <p className="mt-3 p-3 text-sm text-gray-500 max-h-[120px] overflow-hidden overflow-y-scroll scrollbar-thin scrollbar-track-blue scroll-p-[24rem] scrollbar-thumb-[#e5e6e7f6]">
                  <span className="text-xl">&ldquo;</span>
                  {post.Text}
                  <span className="text-xl">&rdquo;</span>
                </p>
              </blockquote>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
