import React from 'react'
import { RotateLoader } from 'react-spinners'

function Loading() {
  return (
    <div className="h-screen w-screen z-50 absolute bg-white top-0 flex flex-col justify-center items-center">
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
            className="w-2/4 md:w-1/6"
            alt='Madhur Exports Logo'
          />
          <RotateLoader color="#c50303" size={8} className="mt-20"/>
        </div>
  )
}

export default Loading