import React from "react";

function BottomMob() {
  return (
    <div className="md:hidden flex justify-between w-screen bottom-0 fixed bg-white px-10 py-2 z-50">
      <a href="tel:9354433416">
        <img
          src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681582283/Components/output-onlinepngtools_2_gpunfu.png"
          width="32px"
        />
      </a>
      <div className="w-[2px] bg-dark-grey" />
      <a href="https://wa.link/8cjevd">
        <img
          src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681582285/Components/output-onlinepngtools_gposvv.png"
          width="32px"
        />
      </a>
      <div className="w-[2px] bg-dark-grey" />
      <a href="mailto:support@madhurexports.in">
        <img
          src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681582283/Components/output-onlinepngtools_1_lr7fxe.png"
          width="32px"
        />
      </a>
    </div>
  );
}

export default BottomMob;
