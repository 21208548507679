import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

function Header() {
  const [mobNav, setMobNav] = useState(false);
  const [active, setActive] = useState(false);

  
  const mobNavToggle = () => {
    const line1 = document.getElementById("line1").style
    const line2 = document.getElementById("line2").style
    const line3 = document.getElementById("line3").style

    if(active === false){
      line1.transform = "rotate(45deg)"
      line1.position = "absolute"
      line2.display = "none"
      line3.transform = "rotate(-45deg)"
      line3.position = "absolute"
      setMobNav(!mobNav);
      setActive(!active)
    }else{
      line1.transform = "rotate(0deg)"
      line1.position = "relative"
      line2.display = "block"
      line3.transform = "rotate(0deg)"
      line3.position = "relative"
      setMobNav(!mobNav);
      setActive(!active)
    }
    
  };

  return (
    <div className="fixed h-22 top-0 z-20 flex w-screen border-b bg-white justify-between items-center py-2 md:py-5 md:px-10 ">
      <Link to="/">
        <img
          src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
          alt="Madhur Exports logo"
          width="120px"
        />
      </Link>
      <nav className="hidden md:flex gap-10 items-center">
        <ul className="list-none flex md:gap-10 items-center">
          <NavLink to="/about">
            <li className="cursor-pointer">About</li>
          </NavLink>
          <NavLink to="/customization">
            <li className="cursor-pointer oemDropDown">
              OEM/ODM
              <div className="bg-white w-44 none oemDropped absolute border rounded py-5">
                <NavLink to="/organic" className="px-5">
                  We are Organic
                </NavLink>
              </div>
            </li>
          </NavLink>
          <NavLink to="/products">
            <li className="cursor-pointer productsDropDown">
              Products
              <div className="bg-white w-40 none productsDropped absolute border rounded py-5 flex flex-col gap-4">
                <NavLink to="/products/men_wear" className="px-5">
                  Menswear
                </NavLink>
                <NavLink to="/products/women_wear" className="px-5">
                  Womenswear
                </NavLink>
                <NavLink to="/products/knits_wear" className="px-5">
                  Knitswear
                </NavLink>
                <NavLink to="/products/kids_wear" className="px-5">
                  Kidswear
                </NavLink>
                <NavLink to="/products/uniform" className="px-5">
                  Corporate Uniform
                </NavLink>
                <NavLink to="/products/accessories" className="px-5">
                  Accessories
                </NavLink>
              </div>
            </li>
          </NavLink>
          <NavLink to="/process">
            <li className="cursor-pointer">Process</li>
          </NavLink>
          <li className="cursor-pointer moreDropDown">
            More
            <div className="bg-white w-48 none moreDropped absolute border rounded py-5 flex-col">
              <NavLink to="/faq" className="px-5">
                FAQ
              </NavLink>
              <NavLink to="/blogs" className="px-5">
                Blogs
              </NavLink>
              <NavLink to="/certificate" className="px-5">
                Verify Certificate
              </NavLink>
            </div>
          </li>
        </ul>
        <Link to="/contact">
          <button className="bg-white text-logo-red px-5 py-2 rounded shadow-lg hover:bg-logo-red hover:text-white z-10 duration-300">
            Contact us
          </button>
        </Link>
      </nav>


      {/*----------Mobile Navigation----------*/}
      <div
        className="md:hidden flex flex-col gap-1 pr-5 z-[999] p-2 mr-4 h-8 w-14 justify-center items-center"
        onClick={mobNavToggle}
      >
        <span className="w-5 h-0.5 border border-slate-600 bg-slate-800 duration-300 ease-in-out" id="line1"></span>
        <span className="w-5 h-0.5 border border-slate-600 bg-slate-800 duration-300 ease-in-out" id="line2"></span>
        <span className="w-5 h-0.5 border border-slate-600 bg-slate-800 duration-300 ease-in-out" id="line3"></span>
      </div>
      <div
        id="mobNavMenu"
        className={
          mobNav
            ? "h-screen absolute w-screen top-0 bg-white flex flex-col md:hidden navBarActive"
            : "navBarHide"
        }
      >
        <div className="w-full border-b py-2 flex h-fit justify-between items-center pr-5">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
              alt="Madhur Exports logo"
              width="120px"
            />
          </Link>
        </div>
        <div className="flex flex-col justify-center">
          <nav className="w-screen">
            <ul>
              <li className="w-full ml-10 my-5 border-b">
                <Link to="/about">About</Link>
              </li>
              <li className="w-full ml-10 my-5 border-b">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="0">
                    <AccordionItemHeading>
                      <AccordionItemButton>Services</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="flex flex-col gap-2 mt-2 ml-5">
                        <Link to="/organic">We are Organic</Link>
                        <Link to="/customization">OEM/ODM</Link>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </li>
              <li className="w-full ml-10 my-5 border-b">
                <Link to="/products">Products</Link>
              </li>
              <li className="w-full ml-10 my-5 border-b">
                <Link to="/process">Process</Link>
              </li>
              <li className="w-full ml-10 my-5 border-b">
                <Accordion allowZeroExpanded>
                  <AccordionItem key="0">
                    <AccordionItemHeading>
                      <AccordionItemButton>More</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="flex flex-col gap-2 mt-2 ml-5">
                        <Link to="/faq">FAQ</Link>
                        <Link to="/blogs">Blogs</Link>
                        <Link to="/certificate">Verify Certificate</Link>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </li>
            </ul>
            <Link to="/contact">
              <button className="ml-10 mt-5 border px-7 py-4 rounded-xl shadow-lg">
                Contact Us
              </button>
            </Link>
          </nav>
          <div className="flex w-screen justify-between px-10 mt-20">
            <a
              href="https://www.Facebook.com/madhurexports"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614162/twitterAsset_6_owxsfc.jpg"
                alt="Social Link Madhur Exports"
                width="40px"
              />
            </a>
            <a
              href="https://www.Instagram.com/Madhurexports"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614162/twitterAsset_5_umtxn1.jpg"
                alt="Social Link Madhur Exports"
                width="40px"
              />
            </a>

            <a
              href="https://in.linkedin.com/company/madhurexports"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614162/twitterAsset_3_itmguq.jpg"
                alt="Social Link Madhur Exports"
                width="40px"
              />
            </a>

            <a
              href="https://www.youtube.com/@madhurexports"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614161/twitterAsset_4_lx3sv3.jpg"
                alt="Social Link Madhur Exports"
                width="40px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
