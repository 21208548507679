import React, { useEffect, useState } from "react";
import { Team } from "../components/";
import Header from "../components/Header";
import Loading from "../components/Loading";

const More = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Team />
        </>
      )}
    </div>
  );
};

export default More;
