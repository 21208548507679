import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="w-screen h-[70vh]">
      <Header />
      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-3xl font-bold">404</h1>
        <h1 className="text-3xl font-bold">Page Not Found</h1>
        <Link to="/">
          <button className="bg-green-500 text-white px-5 py-2 mt-10 rounded-md">
            Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
