import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import db from "../firebase";
import { Link } from "react-router-dom";
import { RotateLoader } from "react-spinners";

function BlogPage() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [allBlogs, setAllBlogs] = useState("");
  const [loading, setLoading] = useState(false);

  const blogCollectionRef = collection(db, "Blog");

  useEffect(() => {
    setLoading(true);
    id && getBlogDetails();

    const getBlogs = async () => {
      const data = await getDocs(blogCollectionRef);
      setAllBlogs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    };
    getBlogs();
  }, [id]);

  const getBlogDetails = async () => {
    const blogRef = doc(db, "Blog", id);
    const blogDetails = await getDoc(blogRef);
    setBlog(blogDetails.data());
  };

  return (
    <div>
      {loading ? (
        <div className="h-screen w-screen z-50 absolute bg-white top-0 flex flex-col justify-center items-center">
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
            className="w-1/6"
          />
          <RotateLoader color="#c50303" size="8" className="mt-20" />
        </div>
      ) : (
        <div className="w-screen flex flex-col items-center">
          <Header />
          <div className="mt-32 md:mt-44 flex flex-col items-center border w-11/12 md:w-3/4 p-5 md:p-10">
            <div className="flex w-full items-center">
              <img
                src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
                width="100px"
              />
              <p>
                Madhur Exports
                <span className="px-2">&#x2022;</span>
                <span className="text-slate-400">{blog?.date}</span>
              </p>
            </div>
            <div className="py-10 flex flex-col items-center">
              <h1 className="text-2xl font-bold text-center">{blog?.title}</h1>
              <img src={blog?.IntroImg} className="pt-5 w-3/4" />
            </div>
            <div dangerouslySetInnerHTML={{__html: blog?.content}} className="BlogContent"/>
          </div>

          <div className="mt-10 w-3/4">
            <div className="flex items-center justify-between">
              <h1 className="text-xl">Related Blogs</h1>
              <Link to="/blogs">
                <p>View All</p>
              </Link>
            </div>
            <div className="flex gap-5 overflow-scroll">
              {Array.isArray(allBlogs) &&
                allBlogs.map((item, i) => (
                  <Link to={`/blogs/${item.id}`} key={i}>
                    <div className="border rounded my-5 w-max">
                      <div className="bg-[url('https://images.unsplash.com/photo-1536244955395-0b8a2a5ab5df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHNwb3J0JTIwd2VhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60')] bg-center w-full h-32" />
                      <div className="text-left p-5">
                        <p>{item.Date}</p>
                        <h1 className="font-bold text-2xl">{item.Title}</h1>
                        <p className="overflow-hidden whitespace-nowrap text-ellipsis w-56">
                          {item.Desc}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogPage;
