import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAJUOQEOtgGAggvvpUN8mulcfZu-hg5ePM",
  authDomain: "madhur-exports.firebaseapp.com",
  projectId: "madhur-exports",
  storageBucket: "madhur-exports.appspot.com",
  messagingSenderId: "287729166429",
  appId: "1:287729166429:web:0cbc3648b7cf17b93fc5b4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;