import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../components/Header";
import { getDocs, collection } from "firebase/firestore";
import db from "../firebase";
import Loading from "../components/Loading";

const Faq = () => {
  const [faq, setFaq] = useState();
  const [loading, setLoading] = useState(false);
  const faqRef = collection(db, "Faq");

  useEffect(() => {
    setLoading(true);
    getDocs(faqRef).then((doc) => {
      setFaq(doc.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    });
    window.scrollTo(0,0)
  }, []);

  return (
    <div className="relative py-[100px]">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <div className="container">
            <div className="flex flex-wrap items-center">
              <div className="w-full px-4">
                <div className="mx-auto max-w-[70%px]">
                  <div className="h-[60vh] flex flex-col justify-center">
                    <p className="uppercase text-center font-medium text-xl mb-3">
                      We are here to help you
                    </p>
                    <h1 className="text-blue text-center font-semibold text-6xl mb-3">
                      FAQ
                    </h1>
                  </div>
                  <Accordion allowZeroExpanded className="mt-10">
                    {Array.isArray(faq) &&
                      faq?.map((item, i) => (
                        <AccordionItem
                          key={i}
                          className="mb-2 border py-5 px-2 hover:bg-slate-100"
                        >
                          <AccordionItemHeading className=" text-lg">
                            <AccordionItemButton>{item.Q}</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="pt-2">
                            {item.A}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    {/*{faq.map((item, i) => (
                  <AccordionItem key={i} className="mb-2 border py-5 px-2 hover:bg-slate-100">
                    <AccordionItemHeading className=" text-lg">
                      <AccordionItemButton>{item.Q}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pt-2">{item.A}</AccordionItemPanel>
                  </AccordionItem>
                ))}
                */}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Faq;
