import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="pb-10 overflow-hidden md:mb-0 mb-20 bg-white">
      <div className="w-screen md:h-fit py-10 md:flex">
        <div className="left w-screen flex flex-col md:w-2/4 md:pl-10">
          <div className="flex items-center gap-5">
            <img
              src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
              alt="Madhur Exports logo"
              className="w-[100px] mb-10 md:w-[150px]"
            />
            <img
              src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681584472/Components/Make_In_India_nu0gpb.png"
              width="100px"
              alt=""
            />
          </div>

          <div className="w-screen flex justify-center md:justify-start md:jus gap-10 md:gap-0">
            <div className="flex flex-col gap-7 md:w-1/4">
              <Link to="/about">
                <p className="text-sm">About</p>
              </Link>
              <Link to="/faq">
                <p className="text-sm">FAQ</p>
              </Link>
              <Link to="/terms">
                <p className="text-sm">Terms and Conditions</p>
              </Link>
              <Link to="/privacyPolicy">
                <p className="text-sm">Privacy Policy</p>
              </Link>
            </div>
            <div className="flex flex-col gap-7 ">
              <Link to="/products">
                <p className="text-sm">Our Products</p>
              </Link>
              <Link to="/customization">
                <p className="text-sm">OEM/ODM</p>
              </Link>
              <Link to="/blogs">
                <p className="text-sm">Blogs</p>
              </Link>
              <Link to="/organic">
                <p className="text-sm">We are Organic</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="right mt-10 md:mt-0 ml-5 md:w-2/4">
          <h1 className="font-bold text-lg md:text-xl text-logo-red">
            Contact Us
          </h1>
          <div className="mt-5 md:mt-20 md:flex md:w-full">
            <div className="md:w-2/4">
              <p className="text-sm">
                <span className="font-bold text-sm md:text-base">IND</span>
                <br />
                Noida
              </p>
              <p className="text-sm">
                Phone: +91 93544-33416
                <br />
                Email: madhurexports11@gmail.com
              </p>
            </div>
            <div className="md:w-2/4 mt-10 md:mt-0">
              <p className="text-sm">
                <span className="font-bold text-sm md:text-base">IND</span>
                <br />
                #258, First Floor, Sector - 16,
                <br /> Faridabad, Haryana - 121001
              </p>
              <p className="text-sm">
                Phone: +91 93544-33416
                <br />
                Email: madhurexports11@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:gap-10 gap-4 md:absolute md:right-5 pl-5">
        <a
          href="https://www.Facebook.com/madhurexports"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614162/twitterAsset_6_owxsfc.jpg"
            alt="Social Link Madhur Exports"
            width="40px"
          />
        </a>
        <a
          href="https://www.Instagram.com/Madhurexports"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614162/twitterAsset_5_umtxn1.jpg"
            alt="Social Link Madhur Exports"
            width="40px"
          />
        </a>

        <a
          href="https://in.linkedin.com/company/madhurexports"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614162/twitterAsset_3_itmguq.jpg"
            alt="Social Link Madhur Exports"
            width="40px"
          />
        </a>

        <a
          href="https://www.youtube.com/@madhurexports"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680614161/twitterAsset_4_lx3sv3.jpg"
            alt="Social Link Madhur Exports"
            width="40px"
          />
        </a>
      </div>
      <div className="flex w-screen justify-center md:justify-start text-center md:text-left md:pl-10 mt-10 md:mt-0">
        <p className="text-sm md:text-base w-5/6">
          <button
            onClick={() => navigate("/more")}
            className="border px-2 py-2 rounded-md mb-5 hover:bg-logo-red hover:text-white duration-300 shadow-md"
          >
            Website Design and Development Team
          </button>
          <br />
          Copyright @ 2022 <span className="text-logo-red">Madhur Exports</span>
          , <br />
          All Rights Reserved
        </p>
      </div>
      <div className="flex justify-center">
        <div className="flex gap-10 items-center justify-center px-10 my-5 flex-wrap">
          <a
            href="https://www.exportbureau.com/company_report.html?code=190168&name=madhur_exports"
            target="_blank"
          >
            <img
              src="https://www.exportbureau.com/images/link/icon135x30approved.gif"
              width="135"
              height="30"
              border="0"
            />
          </a>

          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681583803/Components/GOTS_dutlkf.webp"
            width="70px"
          />

          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681584115/Components/msme_uuauzg.webp"
            width="70px"
          />

          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681584256/Components/Sedex_Member_mark-3_webp_c2yegz.webp"
            width="150px"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
