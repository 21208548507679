import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getDocs, collection } from "firebase/firestore";
import db from "../firebase";
import Header from "../components/Header";
import { RotateLoader } from "react-spinners";
import { Hero } from "../components";

function OurBlogs() {
  const [blog, setBlog] = useState("");
  const blogCollectionRef = collection(db, "Blog");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getBlogs = async () => {
      const data = await getDocs(blogCollectionRef);
      setBlog(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    };
    getBlogs();
  }, []);

  const title1 = `Our Blogs`;
  const text1 = `This Blog page keeps you up to date on the clothing manufacturing process, fabrics, fashion trends, apparel and fashion industry's technology, clothing industry's new and updates`;

  return (
    <div className="mx-auto lg:px-5 px-2 py-10 overflow-hidden bg-white">
      {loading ? (
        <div className="h-screen w-screen z-50 absolute bg-white top-0 flex flex-col justify-center items-center">
          <img
            src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680407223/logo_i7b8ov.svg"
            className="w-1/6"
          />
          <RotateLoader color="#c50303" size="8" className="mt-20" />
        </div>
      ) : (
        <div>
          <Header />
          <div className="mx-auto max-w-7xl text-center ">
            <Hero
              title={title1}
              content={text1}
              buttonLink="/about"
              buttonText="About Us"
            />
            <div className="my-20">
              <h3 className="font-semibold text-black text-xl mb-10">
                Latest Posts
              </h3>
              <div className="flex flex-col gap-10 justify-center m-0">
                {Array.isArray(blog) &&
                  blog.map((item, i) => (
                    <Link to={item.id} key={i}>
                      <div className="relative group bg-white max-w-md mx-auto rounded-md drop-shadow-lg overflow-hidden md:max-w-3xl hover:scale-105 transition-all duration-300 ease-in-out">
                        <a className="md:flex md:h-60" href={item.href}>
                          <div className="md:shrink-0 h-[250px] md:h-full">
                            <img
                              src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680977116/Blogs/pexels-artem-podrez-4783333_1_mslfqh.jpg"
                              alt={item.imageAlt}
                              layout="fill"
                              className="w-full h-full object-cover scale-90 rounded-xl"
                            />
                          </div>

                          <div className="p-10 mb-5">
                            <h2 className="w-full text-left mb-2 capitalize tracking-wide leading-tight">
                              {item.date}
                            </h2>
                            <h4 className="w-full text-left capitalize text-2xl font-semibold text-black">
                              {item.title}
                            </h4>
                            <p className="text-[12px] first-letter:uppercase mt-2 text-slate-500 text-justify text-ellipsis">
                              {item.desc}
                            </p>
                          </div>
                        </a>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OurBlogs;
