import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Hero } from "../components";
import Loading from "../components/Loading";

function Process() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timeout);
  }, []);

  const title = "Madhur Exports: Exploring Our Manufacturing Process";
  const subTitle =
    "Our production process involves several stages, each of which is carefully monitored to ensure that our products meet the highest quality standards. Here's a brief overview of our production process:";
  const title1 = "Yarn Selection";
  const title2 = "Knitting";
  const title3 = "Dyeing and Finishing";
  const title4 = "Cutting";
  const title5 = "Embroidery";
  const title6 = "Printing";
  const title7 = "Stitching";
  const title8 = "Quality Control";
  const title9 = "Packing";

  const content1 =
    "We start by carefully selecting the finest quality yarns from trusted suppliers. Our team of experts conducts rigorous quality checks to ensure that the yarn is of the right thickness, strength, and color.";
  const content2 =
    "Once the yarn is selected, we use advanced knitting machines to weave it into high-quality fabrics. Our machines are capable of producing a variety of knitted fabrics, including single jersey, interlock, rib, and more.";
  const content3 =
    "We use eco-friendly dyes to color our fabrics and ensure that they are long-lasting and do not fade over time. After dyeing, the fabrics undergo a series of finishing processes, including brushing, shearing, and compacting, to give them the desired texture and feel.";
  const content4 =
    "Once the fabrics have been dyed, we move onto the cutting process. Our skilled workers use advanced cutting machines to cut the fabrics into the desired shape and size. We use computerized patterns to ensure that the cutting process is accurate and efficient.";
  const content5 =
    "We use computerized embroidery machines to add intricate and detailed designs to our garments. Our skilled technicians ensure that the embroidery is of the highest quality, with precise stitching and vibrant colors. We use high-quality threads and materials to ensure that the embroidery lasts for the life of the garment.";
  const content6 =
    "We use advanced printing techniques such as screen printing, digital printing, and sublimation printing to produce high-quality designs on our garments. Our team selects the best printing technique based on the specific requirements of the design and the fabric. Our eco-friendly and water-based inks are safe for the environment and meet international standards.";
  const content7 =
    "After the fabrics have been cut, we move onto the stitching process. We use state- of-the-art sewing machines and skilled workers to stitch the fabrics together. Our workers pay close attention to detail to ensure that each garment is of the highest quality.";
  const content8 =
    "We take quality control seriously at Madhur Exports. We have a dedicated team of quality control professionals who inspect each garment at every stage of the production process. This ensures that any issues are identified and corrected before the final product is shipped to our clients.";
  const content9 =
    "After the garments have passed our strict quality control measures, we move onto the packing process. We use eco-friendly packaging materials to pack the garments carefully to ensure that they arrive at our clients' locations in perfect condition.";

  const img1 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965613/Process/Yarn_c5iuej.jpg";
  const img2 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965613/Process/Knitting_cpfq2f.jpg";
  const img3 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965610/Process/Dyeing_and_finishing_urirzk.jpg";
  const img4 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965610/Process/Cutting_jp2ucg.jpg";
  const img5 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680976259/OEM/pexels-olga-kalinina-7448412_rofy51.jpg";
  const img6 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680976129/OEM/d-ng-ph-c-h-i-tri-u-euOA6oQIfIc-unsplash_1_jynlcb.jpg";
  const img7 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965610/Process/Stitching_mcxcaw.jpg";
  const img8 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965750/Process/WhatsApp_Image_2023-04-06_at_3.27.14_AM_1_nkuxfz.jpg";
  const img9 =
    "https://res.cloudinary.com/dqj5beowr/image/upload/v1680965611/Process/Packing_i5ecch.jpg";

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="min-h-screen h-auto">
          <Header />
          <section className="w-screen">
            <section className="md:h-screen w-screen ">
              <Hero
                title={title}
                content={subTitle}
                buttonLink="/contact"
                buttonText="Contact Us"
              />
            </section>

            <div className="mt-10 flex flex-col items-center bg-white">
              {/*------Yarn Selection------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img1}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title1}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content1}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Knitting------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img2}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title2}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content2}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Dyeing and Finishing------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img3}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title3}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content3}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Cutting------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img4}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title4}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content4}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Embroidery------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img5}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title5}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content5}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Printing------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img6}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title6}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content6}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Stitching------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img7}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title7}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content7}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Quality Control------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img8}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title8}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content8}
                  </p>
                </div>
              </div>
              <span className="w-screen justify-center flex my-10">
                <img
                  src="https://res.cloudinary.com/dqj5beowr/image/upload/v1680729582/downArrow_i0snxl.png"
                  width="50px"
                />
              </span>

              {/*------Packing------*/}
              <div className="flex flex-col md:flex-row h-[500px] md:h-[400px] w-5/6 drop-shadow-xl border rounded-md">
                <div className="Image h-2/4 min-h-min md:h-full md:w-1/2">
                  <img
                    src={img9}
                    className="w-full h-full object-cover rounded-t-md md:rounded-l-md md:rounded-tr-none"
                  />
                </div>
                <div className="Content h-2/4 min-h-min md:h-full  md:w-1/2 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                  <h1 className=" text-lg md:text-2xl font-bold text-logo-red">
                    {title9}
                  </h1>
                  <p className="md:w-3/4 text-justify text-xs md:text-base">
                    {content9}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="w-full bg-[#e5e5e5] py-10 flex justify-center">
            <p className="w-5/6 py-10 text-justify text-xs md:text-base">
              At Madhur Exports, we are committed to providing our clients with
              the highest quality products. We take pride in our manufacturing
              process and believe that it is essential to provide transparency
              about how our products are made. If you have any questions about
              our manufacturing process or would like to learn more about our
              products, please do not hesitate to contact us. We would be happy
              to answer any questions you may have.
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default Process;
