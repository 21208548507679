import React, { useState, useEffect } from "react";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import db from "../firebase";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import { findAllByTestId } from "@testing-library/dom";
import Loading from "../components/Loading";
import Hero from "../components/layouts/Hero";

function Home() {
  const homeRef = doc(db, "Pages", "Home");
  const testimonialCollectionRef = collection(db, "Testimonials");
  const oemCarouselRef = collection(db, "HomeOEMCarousel");

  const [homeContent, setHomeContent] = useState("");
  const [oemCarousel, setOEMCarousel] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDoc(homeRef).then((doc) => {
      setHomeContent(doc.data());
    });

    const getOEMCarousel = async () => {
      const data = await getDocs(oemCarouselRef);
      setOEMCarousel(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    getOEMCarousel();

    const getTestimonials = async () => {
      const data = await getDocs(testimonialCollectionRef);
      setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    };
    getTestimonials();
  }, []);

  const settingsOEM = {
    dots: findAllByTestId,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: true,
    cssEase: "linear",
  };

  const settingsGallery = {
    dots: findAllByTestId,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          centerPadding: "20px",
        },
      },
    ],
  };

  var settingsTestimonials = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <section className="md:h-screen w-screen border">
            <Header />
            <Hero
              bg_image="https://res.cloudinary.com/dqj5beowr/image/upload/v1680343239/world-map_yntfwy.svg"
              title={homeContent?.H1}
              content={homeContent?.H2}
              buttonLink={'/about'}
              buttonText={'About Us'}
            />
          </section>
          {/*------Clothing 1------*/}
          <section className="md:h-screen md:w-screen flex items-center justify-center bg-white">
            <div className="flex flex-col md:flex-row w-full h-full justify-center items-center md:gap-10 py-10 md:py-0 bg-logo-blue">
              <div className="w-5/6 md:max-w-2xl h-full md:flex md:flex-col justify-center py-5 md:py-20">
                <p className="text-white tracking-widest text-sm md:text-base">
                  {homeContent?.Clothing1Name}
                </p>
                <h1 className="text-white font-bold md:text-2xl pb-5 my-10 text-lg">
                  {homeContent?.Clothing1H1}
                </h1>
                <p className="text-white text-justify text-sm md:text-base">
                  {homeContent?.Clothing1H2}
                </p>
              </div>
              <img
                src={homeContent?.Clothing1Img}
                alt="clothing 1"
                className="md:h-full"
              />
            </div>
          </section>

          {/*------OEM/ODM------*/}
          <section className="w-screen md:h-screen bg-white">
            <div className="p-5 md:p-20 bg-white w-screen h-fit md:h-52 flex flex-col justify-between items-center md:items-start">
              <h1 className=" text-lg md:text-2xl font-bold">{homeContent?.OEMH1}</h1>
              <p className="md:text-left md:w-2/4 text-sm md:text-base text-justify">
                {homeContent?.OEMH2}
              </p>
            </div>
            <div className="flex justify-center h-fit md:h-[calc(100vh-13rem)] items-center w-screen bg-slate-200">
              <Slider {...settingsOEM} className="w-4/5">
                {oemCarousel.map((item, i) => (
                  <div key={i} className="my-10">
                    <Link to="/products">
                    <div className="container flex flex-col md:flex-row w-full md:w-3/4 h-[500px] md:h-[400px] md:justify-center">
                      <div className="h-2/4 min-h-min md:h-full w-full md:w-2/4 rounded-t-md md:rounded-l-md md:rounded-tr-none bg-no-repeat bg-top">
                        <img src={item.Img} className="w-full h-full rounded-t-md md:rounded-t-none md:rounded-l-md md:rounded-tl-md object-cover"/>
                      </div>
                      <div className="Content h-full min-h-min md:h-full md:w-2/4 bg-white px-2 py-5 md:pl-10 flex flex-col items-center md:items-start justify-center rounded-b-md md:rounded-r-md md:rounded-bl-none">
                        <h1 className="text-lg md:text-2xl font-bold text-logo-red">
                          {item.H1}
                        </h1>
                        <p></p>
                        <p className="md:w-3/4 text-justify text-sm md:text-base">{item.H2}</p>
                      </div>
                    </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          {/*------Clothing 2------*/}
          <section className="md:h-screen md:w-screen flex items-center justify-center bg-white">
            <div className="flex flex-col md:flex-row w-full h-full justify-center items-center md:gap-32">
              <div className="w-5/6 md:max-w-2xl h-full md:flex md:flex-col justify-center py-5 md:py-20">
                <p className="tracking-widest">{homeContent?.Clothing2Name}</p>
                <h1 className="font-bold  text-lg md:text-2xl pb-5 md:my-10 text-logo-red">
                  {homeContent?.Clothing2H1}
                </h1>
                <p className="text-sm text-justify md:text-base">
                  {homeContent?.Clothing2H2}
                </p>
              </div>
              <img
                src={homeContent?.Clothing2Img}
                alt="clothing 1"
                className="md:h-4/5"
              />
            </div>
          </section>

          {/*------Gallery------*/}
          <section className="h-fit md:h-screen flex flex-col justify-center mt-10 md:mt-0 bg-white">
            <div className="w-screen bg-slate-200 flex justify-center">
              <Slider {...settingsGallery} className="w-3/4 my-10">
                <div>
                  <img
                    src={homeContent?.GalleryImg1}
                    width="400px"
                    className="px-2"
                    alt="Madhur Exports Gallery"
                  />
                </div>
                <div>
                  <img
                    src={homeContent?.GalleryImg2}
                    width="400px"
                    className="px-2"
                    alt="Madhur Exports Gallery"
                  />
                </div>
                <div>
                  <img
                    src={homeContent?.GalleryImg3}
                    width="400px"
                    className="px-2"
                    alt="Madhur Exports Gallery"
                  />
                </div>
                <div>
                  <img
                    src={homeContent?.GalleryImg4}
                    width="400px"
                    className="px-2"
                    alt="Madhur Exports Gallery"
                  />
                </div>
              </Slider>
            </div>
          </section>

          {/*------Testimonials------*/}
          <section className="h-fit md:h-screen">
            <div className="flex flex-col w-screen items-center justify-center bg-white">
            <h1 className=" text-xl md:text-3xl mt-10 text-logo-blue font-bold mb-10">
              Testimonials
            </h1>
              <Slider {...settingsTestimonials} className="w-4/5">
                {posts.map((post, i) => (
                  <div
                    key={i}
                    className="py-8 max-w-[350px] rounded-3xl bg-[#f5f5f5] shadow-md mb-5 pb-10"
                  >
                    <img
                      alt="profile-pic"
                      src="https://res.cloudinary.com/dqj5beowr/image/upload/v1681583211/Components/output-onlinepngtools_3_crmk7x.png"
                      className="mx-auto h-[100px] w-[100px] rounded-full object-cover shadow-md shadow-blue"
                    />
                    <blockquote className="mt-3 flex flex-col justify-between rounded-lg md:px-5 text-center">
                      <div className="ml-4 text-sm">
                        <p className="text-sm font-bold text-gray-700">
                          {post.Name}
                        </p>
                        <p className="mt-1 text-sm font-medium text-gray-500">
                          {post.Title}
                        </p>
                      </div>
                      <p className="mt-3 p-3 mb-10  text-gray-500 text-sm h-fit max-h-[250px]">
                        <span className="text-sm">&ldquo;</span>
                        {post.Text}
                        <span className="text-sm">&rdquo;</span>
                      </p>
                    </blockquote>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Home;